import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => {
  const wine = props.pageContext.product;
  console.log(wine)
  return (
  <Layout nav="shop" >
  <Row>
    <Col xs={12} sm={12} md={4} mdOffset={1}>
      <div className="tab-content mb-10 send-bt">
        <div className="pro-large-img tab-pane active" style={{textAlign: 'center'}}>
          <img src={wine['Product Shots'] && wine['Product Shots'][0].url} alt={wine.Name} />
        </div>
        </div>
        {/*<div className="pro-thumb-img-slider slick-initialized slick-slider"><button type="button" className="arrow-prev slick-arrow" style={{display: 'block'}}><i className="fa fa-angle-left"></i></button></div>*/}
    </Col>
    <Col xs={12} sm={12} md={6}>
      <div className="product-details section send-bt">
        <h2 className="title" style={{fontSize: '24px'}}>{wine.Wine.Name} {wine.Vintage}</h2>
        <div className="short-desc section" style={{paddingTop: '10px'}}>
          <h5 className="pd-sub-title">${wine['Retail Price'] && wine['Retail Price'].toFixed(2)}</h5>
          <p>
            <strong>Vintage:</strong> {wine.Vintage}<br/>
            <strong>Appelation:</strong> {wine.Appellation}<br/>
            <strong>Varietal:</strong> {(wine['Varietal(s)'] || []).map((v, i) => i ? ` - ${v}` : v)}<br/>
          </p>
          <p>{(wine.Description || '').replace(/<\/?[^>]+(>|$)/g, "")}</p>
        </div>
        <div className="quantity-cart section"></div>
          {/*wine.Status === 'Available' ?
          <a className="btn btn-gold snipcart-add-item" href="#" style={{width: '100%'}}
            data-item-id = {wine.SKU}
            data-item-metadata = {`{"type": "${wine.Type}"}`}
            data-item-price = {wine['Retail Price']}
            data-item-url = "https://www.pourmgmt.com/products"
            data-item-image = {wine['Product Shots'] && wine['Product Shots'][0].url}
            data-item-name = {wine.Name + (wine.Vintage ? ` ${wine.Vintage}` : '')}
          >
          <button style={{marginTop: '5px', width: '100%'}}>Add to cart - ${wine['Retail Price']}</button>
            </a>
            :
          <div>
            <button className="snipcart-add-item" style={{marginTop: '5px', width: '100%'}}>{wine.Status} - ${wine['Retail Price']}</button>
          </div>
        */}
      </div>
    </Col>
  </Row>
  </Layout>
  )
}
